import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { SidebarBannerBuilderComponent } from '@tvlgiao/bc-supermarket-builder-components';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "sidebar"
    }}>{`Sidebar`}</h1>
    <h2 {...{
      "id": "banners-on-left-sidebar"
    }}>{`Banners on Left Sidebar`}</h2>
    <p>{`Banner above Facebook Like Page block:`}</p>
    <p><img alt="sidebar banner 1" src={require("../img/home1-sidebar-banner1.jpg")} /></p>
    <p>{`Banner below Facebook Like Page block:`}</p>
    <p><img alt="sidebar banner 2" src={require("../img/home1-sidebar-banner2.jpg")} /></p>
    <p>{`In `}<strong parentName="p">{`Marketing`}</strong>{` > `}<strong parentName="p">{`Banners`}</strong>{`, click `}<strong parentName="p">{`Create a Banner`}</strong>{` button. In `}<strong parentName="p">{`Banner Content`}</strong>{`, click `}<inlineCode parentName="p">{`HTML`}</inlineCode>{` button to open `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <p>{`Use the editor below to generate HTML. Click `}<strong parentName="p">{`Export HTML`}</strong>{` button, copy & paste the generated HTML to `}<strong parentName="p">{`HTML Source Editor`}</strong>{`.`}</p>
    <SidebarBannerBuilderComponent mdxType="SidebarBannerBuilderComponent" />
    <p>{`Choose other options:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Show on Page`}</strong>{`: `}<inlineCode parentName="li">{`Search Results Page`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Visible`}</strong>{`: Yes`}</li>
      <li parentName="ul"><strong parentName="li">{`Location`}</strong>{`: `}<inlineCode parentName="li">{`Top of Page`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "facebook-like-page"
    }}>{`Facebook Like Page`}</h2>
    <p><img alt="Facebook like page" src={require("../img/home1-facebook-like-page.jpg")} /></p>
    <p>{`You can show your own Facebook Like Page block or hide it completely. `}</p>
    <p>{`From `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Social Media Icons`}</strong>{` > Enter your facebook page name in `}<strong parentName="p">{`Facebook page`}</strong>{` field. For example if your Facebook page URL is `}<a parentName="p" {...{
        "href": "https://www.facebook.com/papathemes/"
      }}>{`https://www.facebook.com/papathemes/`}</a>{`, ONLY input `}<inlineCode parentName="p">{`papathemes`}</inlineCode>{`.`}</p>
    <p>{`To hide this block, just leave it empty.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      